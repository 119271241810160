/*

  h o m e
  Home

  :description:
  The Home component.

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :components:
import { Content } from '../../components/Content'
import { RedirectIfNotLoggedIn } from '../../components/RedirectIfNotLoggedIn'
import { getReportAsync, selectGetReportResponseResponse } from '../../features/api/apiSlice'

//
//  :component:
export const Report = props => {
  const dispatch = useDispatch()

  const report = useSelector(selectGetReportResponseResponse)

  useEffect(() => {
    if (!report) {
      dispatch(getReportAsync())
    }
  }, [dispatch, report])

  const [distributor, setDistributor] = useState('')
  const [showHasNoTexture, setShowHasNoTexture] = useState(false)
  const [showHasNoThumbnail, setShowHasNoThumbnail] = useState(false)
  const [room, setRoom] = useState(false)
  const [arid, setARID] = useState('')

  /*
  const renderMissingVisualAssets = () => {
    if (!report) {
      return
    }
    const htmls = []
    report.map((r, i) => {
      if (!r.texture || !r.thumbnail) {
        htmls.push(
          <tr
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-sky-700 cursor-pointer"
            key={r.arid + r.room + r.s3}
          >
            <td className="px-6 py-4">{r.room}</td>
            <td className="px-6 py-4" title={r.s3}>
              {_getDistributorAndLanguageFromS3URL(r.s3)}
            </td>
            <td className="px-6 py-4">{r.arid}</td>
            <td className="px-6 py-4">{!r.texture ? '!' : ''}</td>
            <td className="px-6 py-4">{!r.thumbnail ? '!' : ''}</td>
          </tr>
        )
      }
      return true
    })
    if (htmls.length === 0) {
      return <h2 className="text-green-600 text-2xl text-center mt-4">Ok, there are no missing visual assets</h2>
    }
    return (
      <div className="flex flex-col">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4 mb-4 text-center">
          <thead className="text-xs text-gray-700 uppercase bg-green-100">
            <tr>
              <th scope="col" class="px-6 py-3">
                Room
              </th>
              <th scope="col" class="px-6 py-3">
                Distributor
              </th>
              <th scope="col" class="px-6 py-3">
                ARID
              </th>
              <th scope="col" class="px-6 py-3">
                Texture
              </th>
              <th scope="col" class="px-6 py-3">
                Thumbnail
              </th>
            </tr>
          </thead>
          <tbody>{htmls}</tbody>
        </table>
      </div>
    )
  }
  */

  const UUID_TO_NAME = {
    'efad740a-0485-473d-bfa5-44a93cf930cb': 'Newegg US',
    'bcc57792-ae38-488d-a3b7-1cc5573d2198': 'FNAC FR',
    'c05073d9-76fc-4d1b-8934-6f13de74b03a': 'FNAC ES',
    'aef4d3e1-04fd-436d-8fa1-a14a35f369db': 'Portinos US',
    'e606a4db-ed59-40b4-80bd-8e2bea57d2d6': 'Fast Shop BR',
    '2e9ff179-f3db-4b65-bdce-f9debba700b1': 'Dell US',
    '79f867a3-04a6-4788-afbe-396f0292e647': 'Media Markt TR',
    '19c9d0ee-25c4-441c-a0f1-9c3d06a0da04': 'Casas Bahia BR',
    '4c643537-d6c5-424d-8901-003804cba9d8': 'Pontofrio BR',
    '38305285-a09c-4ef0-ae1a-f3d5b09c739c': 'EXTRA BR',
    '222a92de-041e-4b02-8caf-0208af80e3d1': 'Sharaf EN',
    '222a92de-041e-4b02-8caf-0208af80e3d1': 'Sharaf AR',
    '14be76cb-b523-46fc-a034-6372f78ab1a9': 'Lenovo GB',
  }

  const renderReport = () => {
    if (!report) {
      return
    }
    const htmls = []
    /*
    {
        "distributor": "bcc57792-ae38-488d-a3b7-1cc5573d2198",
        "language": "fr",
        "arid": "8000229",
        "name": "Dell PC Portable Dell XPS 13 Plus 9320 13.4\"",
        "files": {
            "thumbnail": true,
            "professional": true,
            "gaming": false,
            "student": false,
            "remote": false,
            "editor": false
        },
        "url": "https://www.fnac.com/a/a16940808"
    },
    */

    const renderAssetStatusUIElement = (row, key) => {
      const exists = row.files[key]
      const character = exists ? '✓' : '×'
      const isProd = window.location.href.includes('pc-rooms-ui.')
      const textureURL = `https://pc-rooms${isProd ? '' : '-dev'}.pointandplace.com/v3/visual-assets/textures/${key}/${
        row.arid
      }.png`
      const thumbnailURL = `https://pc-rooms${isProd ? '' : '-dev'}.pointandplace.com/v3/visual-assets/thumbnails/${
        row.arid
      }.png`
      return (
        <div
          title={key}
          className={`w-4 h-4 cursor-pointer text-white text-center ${exists ? 'bg-green-500' : 'bg-red-800'}`}
          onClick={e => {
            window.open(key === 'thumbnail' ? thumbnailURL : textureURL, '_blank')
          }}
        >
          {character}
        </div>
      )
    }

    report.map(row => {
      let canRender = true
      if (distributor && distributor !== '-1') {
        if (row.distributor !== distributor) {
          canRender = false
        }
      }
      let textureCount = 0
      if (row.files.professional) {
        textureCount += 1
      }
      if (row.files.gaming) {
        textureCount += 1
      }
      if (row.files.student) {
        textureCount += 1
      }
      if (row.files.remote) {
        textureCount += 1
      }
      if (row.files.editor) {
        textureCount += 1
      }
      if (textureCount > 0 && showHasNoTexture) {
        canRender = false
      }
      if (row.files.thumbnail && showHasNoThumbnail) {
        canRender = false
      }
      if (room && row.files[room]) {
        canRender = false
      }
      if (arid && !row.arid.startsWith(arid)) {
        canRender = false
      }
      if (!canRender) {
        return false
      }
      htmls.push(
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td className="px-6 py-4">
            {UUID_TO_NAME[row.distributor] ? UUID_TO_NAME[row.distributor] : row.distributor}
          </td>
          <td className="px-6 py-4">{row.arid}</td>
          <td className="px-6 py-4">{renderAssetStatusUIElement(row, 'thumbnail')}</td>
          <td className="px-6 py-4 flex gap-1 ">
            {renderAssetStatusUIElement(row, 'professional')}
            {renderAssetStatusUIElement(row, 'gaming')}
            {renderAssetStatusUIElement(row, 'student')}
            {renderAssetStatusUIElement(row, 'remote')}
            {renderAssetStatusUIElement(row, 'editor')}
          </td>
          <td className="px-6 py-4">{row.name}</td>

          <td className="">
            <a className={`text-blue-600 ${row.url && row.url.includes('http') ? '' : 'eky-removed'}`} href={row.url}>
              View
            </a>
          </td>
        </tr>
      )
      return false
    })
    if (htmls.length === 0) {
      htmls.push(
        <tr>
          <td colSpan={6} className="text-sm text-center p-4">
            No products match the current filters
          </td>
        </tr>
      )
    }
    return (
      <div class="relative overflow-x-auto">
        {htmls.length > 1 ? <h2 className="text-center text-2xl">{htmls.length} products</h2> : ''}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400" style={{ maxHeight: '700px' }}>
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
            <th scope="col" class="px-6 py-3">
              Distributor
            </th>
            <th scope="col" class="px-6 py-3">
              ARID
            </th>
            <th scope="col" class="px-6 py-3">
              Thumbnail
            </th>
            <th scope="col" class="px-6 py-3">
              Textures
            </th>{' '}
            <th scope="col" class="px-6 py-3">
              Name
            </th>
            <th scope="col" class="px-6 py-3">
              URL
            </th>
          </thead>
          <tbody>{htmls}</tbody>
        </table>
      </div>
    )
  }

  const renderToggleSwitch = (text, enabled, handler) => {
    return (
      <button className={`rounded-md p-1 px-2 ${enabled ? 'bg-green-500' : 'bg-gray-500'} mr-4`} onClick={handler}>
        {text}
      </button>
    )
  }

  const renderRoomToggleSwitch = (text, roomId, handler) => {
    const enabled = roomId === room
    return (
      <button
        className={`rounded-md p-1 px-2 ${enabled ? 'bg-green-500' : 'bg-gray-500'} mr-4`}
        onClick={e => setRoom(roomId)}
      >
        {text}
      </button>
    )
  }

  const _renderDistributorsFilter = () => {
    return (
      <div className="mt-4">
        <h4 className="font-bold mb-1">Distributor</h4>
        <select
          className="text-black p-1"
          onChange={e => {
            setDistributor(e.target.value)
          }}
        >
          <option value="-1">Select a Distributor</option>
          <option value="efad740a-0485-473d-bfa5-44a93cf930cb">Newegg US</option>
          <option value="bcc57792-ae38-488d-a3b7-1cc5573d2198">FNAC FR</option>
          <option value="c05073d9-76fc-4d1b-8934-6f13de74b03a">FNAC ES</option>
          <option value="aef4d3e1-04fd-436d-8fa1-a14a35f369db">Portinos US</option>
          <option value="e606a4db-ed59-40b4-80bd-8e2bea57d2d6">Fast Shop BR</option>
          <option value="2e9ff179-f3db-4b65-bdce-f9debba700b1">Dell US</option>
          <option value="79f867a3-04a6-4788-afbe-396f0292e647">Media Markt TR</option>
          <option value="19c9d0ee-25c4-441c-a0f1-9c3d06a0da04">Casas Bahia BR</option>
          <option value="4c643537-d6c5-424d-8901-003804cba9d8">Pontofrio BR</option>
          <option value="38305285-a09c-4ef0-ae1a-f3d5b09c739c">EXTRA BR</option>
          <option value="222a92de-041e-4b02-8caf-0208af80e3d1">Sharaf DG</option>
          <option value="a2f96ae5-ae8f-4d07-8720-5ef45eeb05be">Canada Computers CA</option>
          <option value="14be76cb-b523-46fc-a034-6372f78ab1a9">Lenovo GB</option>
        </select>
        <h4 className="font-bold mb-1">ARID</h4>
        <input value={arid} onChange={e => setARID(e.target.value)} className="rounded-md text-black px-2 py-1" />
      </div>
    )
  }

  const _renderEntirelyMissingFilter = () => {
    return (
      <div className="mt-4">
        <h4 className="font-bold mb-1">Switches</h4>
        <div className="flex gap-2 ">
          <button
            className={`rounded-md p-1 px-2 bg-gray-700 mr-4`}
            onClick={e => {
              setShowHasNoTexture(false)
              setShowHasNoThumbnail(false)
            }}
          >
            Clear
          </button>
          {renderToggleSwitch('No Thumbnail', showHasNoThumbnail, e => setShowHasNoThumbnail(!showHasNoThumbnail))}
          {renderToggleSwitch('0 Textures', showHasNoTexture, e => setShowHasNoTexture(!showHasNoTexture))}
        </div>
      </div>
    )
  }

  const _renderMissingTexturesFilter = () => {
    return (
      <div className="mt-4">
        <h4 className="font-bold mb-1">Missing Textures for</h4>
        <div className="flex gap-2">
          <button className={`rounded-md p-1 px-2 bg-gray-700 mr-4`} onClick={e => setRoom('')}>
            Clear
          </button>
          {renderRoomToggleSwitch('Professional', 'professional')}
          {renderRoomToggleSwitch('Gaming', 'gaming')}
          {renderRoomToggleSwitch('Student', 'student')}
          {renderRoomToggleSwitch('Remote', 'remote')}
          {renderRoomToggleSwitch('Editor', 'editor')}
        </div>
      </div>
    )
  }

  const renderFilteringUI = () => {
    return (
      <div className="w-full mb-6 drop-shadow">
        <h2 className="text-2xl">Filters</h2>
        <div className={`${window.innerWidth <= 1368 ? 'flex-col gap-4' : 'flex  justify-around'}`}>
          {_renderDistributorsFilter()}
          {_renderEntirelyMissingFilter()}
          {_renderMissingTexturesFilter()}
        </div>
      </div>
    )
  }

  return (
    <>
      <RedirectIfNotLoggedIn />

      <Content>
        <div className="container mx-auto px-4 mb-16">
          <div className="w-full bg-gray-900 p-8 rounded-xl mt-4 drop-shadow-xl">
            <h2 className="text-3xl">Report</h2>
            <p className="mb-2 italic text-sm">
              This page shows a report of the products that have been processed for all known distributors.
            </p>
            <hr className="h-px bg-blue-900 border-0" />
            <div className="mt-2">
              {renderFilteringUI()}
              {renderReport()}
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}
